import { ChangeDetectionStrategy, Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

@Component(
	{
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
	})

export class AppComponent
{
constructor(
	private platform: Platform,
	private splashScreen: SplashScreen,
	private statusBar: StatusBar
	)
	{
	this.initializeApp();
	}

private initializeApp()
	{
	this.platform.ready().then(() =>
		{
		this.statusBar.styleDefault();
		this.splashScreen.hide();
		});
	}
}
